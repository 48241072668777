import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/node/work/src/templates/post.template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "the-puzzle"
    }}>{`The puzzle`}</h2>
    <p>{`You and your friends are walking through the park when an evil game master
kidnaps you all and forces you to play a devious game.`}</p>
    <p>{`The game master sits you all down in a room across from one other and explains
the game. The game master says that he will be placing hats on each of your
heads. Each hat will be one of several colors, the maximum number of possible
colors will match the number of people in your group. So if there are six of
you, there will be six different possible colors that the game master can
choose from. However, the game master is sneaky, and can choose to use as few
or as many colors as he sees fit; some of you could end up wearing the same
color hat. You won't be able to see your own hat, but you `}<strong parentName="p">{`will`}</strong>{` be able to
see everybody elses.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1028px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "90.4669260700389%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsSAAALEgHS3X78AAACKElEQVQ4y31T227aQBD1/z/0G5rHXtJQJRECV0WtUqkGrAKNHRuiiBjRW4ov4Nt61+ue3UkcqpLOw+rM7lzPzhjNnjDGyrJsZI2zqiq6BIAqpcRZsnLf3mgRV1LlJTsaTLmoK8aEEHVdIyJee52LdJeJWrRB/3IuigKnv7h+0R3c3gbAWZalaQqwWq3OOv0r12vNDjvbtn1y/Go6nVLBlNZxnZPOsWVZwKqvf50hm83GcZy8KGaz2Xa7zR4EsfI8x9Pd77sDPXNRSVkjOQKjVQA4l1p2ux38wQeqwD3MhOD3zkQjHPCMDqHCGnngILXkWhALGBywquI6Org0iiJHjOsf1jpUfKRZCjcAhKu0ABB5oE/IJo+X2++q+SLPDHyd4NK8ePNpZDYSfBTITz4oAQkpCi5VzU1z+eWj9eF1yQRmwkC1SRJ3z/tm/z10xqqnnNEzgPlu8Pa0G4Uh7g2U7nme7/uu6yyXS7Kjssm6LRs4CAKYLRZz13WRVRGWJAlOKGEUAcRxggpBUksY1CSOESKKIpowVKQIawcYGUC7GhXJqWAaEgCoTMpCC3Xx+M8IjxOR1D5w/tlf1/r/Ui06nAy/XnK1GKzWXZDL44TR3AXrb89emj9/YZIkfRXswjAcPj9a39wos//M9mQy6XfPQOH+Yszn89Neb2zbTy4G/c14PL7yvOFwSGNHHUL1PW80GoFaIv/AYsAZz8QqSG7bwcIA4AkG+/Z/AJlyArDszh6bAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/963824b5395050b46a4c6b9cdcad2752/78fa8/puzzlepic.webp 1028w"],
            "sizes": "(max-width: 1028px) 100vw, 1028px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/963824b5395050b46a4c6b9cdcad2752/a872e/puzzlepic.png 1028w"],
            "sizes": "(max-width: 1028px) 100vw, 1028px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/963824b5395050b46a4c6b9cdcad2752/a872e/puzzlepic.png",
            "alt": "img",
            "title": "You and five friends trapped with colored hats",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Once the game master places all the hats, then one by one he will ask you in
order to write down your best guess as to what your own hat color is. If at
least one of you is able to guess your own hat color, he'll set you all free,
and if not, you all must remain his prisoners!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1028px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "90.4669260700389%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsSAAALEgHS3X78AAACJUlEQVQ4y31T227bMAz1/z/sG9a3Ydi6BGsbNB5abOiA2UuWBLFdJyuKXJDdkjhy6ptkWd6RWHgZlo4PBEmR1OERZdUHwjkviqJWFbQQgoIw4CqloAteHOZbjVVqEVnBT65HpawE51LKqqrQEaeXrZvkIZWVbJr+VZznOfTk692Li6v5fA47TdMkSWAsl8uz1uVtEDZpx4u/9Pvt09ej0YgA07V+ELTab1zHga3n+rcYEkXReDze7XYo3u/3qZEsywaDAR1tNpsjM5dSKPCUF8jebrdAi+LCyIMRBHEEdEiTsnwsJhrBDeiiCek2aGUkMwJXHyUJF6KUEl3ApZXnGaJ3P5xVpPlINEe6BdoJIzCoI06kqjM223/Xw+dZauHpZKnsm9OPn+1agY8cxVQDtABPXRDUmOvaG3xw3r8quMROWEAbx6xz3rW7V/A5F08Vg3kY9rvr9tvOLooQtwB9oiX0PG82m1EewabsBjbsxWLh+x6Sg+AWt2rC0D6OY2jGGFzGYiAESQ1hcGPG0GLHGFx6Ak1Ys5u4AVG9KqokwLQkMOBypXIjNAWaPr4zWeik/0NZfpqsKvN+iRHTTkVjr9Qfg1dmij/FJLR3i9W3Zy/tn7/WSKCnQh7Wy31+srq/12n/2e3hcGh3zsMwPPwY0+n0rNvt9ftPfgx6m16v5/mB4zi0djSh67qB70ODVyL/yMdAMRDiQmiw2oyzXq8RxIdBwmH+bwstAUt0ckXdAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1b498a0df178a032ae59e59e7a3642c0/78fa8/puzzlepic_answered.webp 1028w"],
            "sizes": "(max-width: 1028px) 100vw, 1028px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1b498a0df178a032ae59e59e7a3642c0/a872e/puzzlepic_answered.png 1028w"],
            "sizes": "(max-width: 1028px) 100vw, 1028px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/1b498a0df178a032ae59e59e7a3642c0/a872e/puzzlepic_answered.png",
            "alt": "img",
            "title": "Everyone guessing their own hat color",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Feeling merciful, the game master gives you a few moments to say goodbye to
each other as he believes you are all doomed. This gives you a few moments to
think of a way to try and win his devious game. This is important because once
the game master sits you down, you won't be able to communicate at all to each
other without forfeiting the game.`}</p>
    <p>{`How can you `}<strong parentName="p">{`guarantee`}</strong>{` that at least one of you will guess your own hat color
correctly?`}</p>
    <h2 {...{
      "id": "tldr"
    }}>{`TLDR`}</h2>
    <p>{`Rainbow Hats Puzzle`}</p>
    <ol>
      <li parentName="ol">{`You and
`}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span></span></span></span></span>{` friends are sitting in a room across from one another.`}</li>
      <li parentName="ol">{`Each of you is wearing a colored hat `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`c`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`c_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.5806em",
                    "verticalAlign": "-0.15em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`c`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3117em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "0em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`.`}</li>
      <li parentName="ol">{`The number of unique hat colors is equal to `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span></span></span></span></span>{`.`}</li>
      <li parentName="ol">{`Colors may or may not repeat.`}</li>
      <li parentName="ol">{`You can see everyone elses hat color except your own.`}</li>
      <li parentName="ol">{`No friend can communicate with another once the hats are placed.`}</li>
      <li parentName="ol">{`No friend can hear any other friends guesses`}</li>
    </ol>
    <p><strong parentName="p">{`Goal:`}</strong>{` garuntee that at least one of you can guess their own hat color!`}</p>
    <p>{`Try to solve it for yourself, or keep scrolling for the answer!`}</p>
    <hr></hr>
    <h2 {...{
      "id": "the-solution"
    }}>{`The Solution`}</h2>
    <p>{`This has been one of my favorite math puzzles I've seen over the past year. In
my mind it strikes that beautiful in between of mathematical simplicity, and
hard reasoning. Let's go through that reasoning and the solution step by step:`}</p>
    <p>{`First lets define some core variables based on the puzzle description`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.4306em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`n`}</span></span></span></span></span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of people`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`c`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`c_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.5806em",
                        "verticalAlign": "-0.15em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mord mathnormal"
                      }}>{`c`}</span><span parentName="span" {...{
                        "className": "msupsub"
                      }}><span parentName="span" {...{
                          "className": "vlist-t vlist-t2"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.3117em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-2.55em",
                                  "marginLeft": "0em",
                                  "marginRight": "0.05em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "2.7em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mathnormal mtight"
                                    }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                              "className": "vlist-s"
                            }}>{`​`}</span></span><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.15em"
                              }
                            }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ith colored hat (represented as an integer between 0 and n-1)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`S`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`S_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.8333em",
                        "verticalAlign": "-0.15em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.05764em"
                        }
                      }}>{`S`}</span><span parentName="span" {...{
                        "className": "msupsub"
                      }}><span parentName="span" {...{
                          "className": "vlist-t vlist-t2"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.3117em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-2.55em",
                                  "marginLeft": "-0.0576em",
                                  "marginRight": "0.05em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "2.7em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mathnormal mtight"
                                    }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                              "className": "vlist-s"
                            }}>{`​`}</span></span><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.15em"
                              }
                            }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sum of colored hats seen by person "i", or `}<span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><msubsup parentName="mrow"><mo parentName="msubsup">{`∑`}</mo><mrow parentName="msubsup"><mi parentName="mrow">{`j`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`0`}</mn></mrow><mi parentName="msubsup">{`n`}</mi></msubsup><msub parentName="mrow"><mi parentName="msub">{`c`}</mi><mi parentName="msub">{`j`}</mi></msub></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`{\\sum_{j=0}^{n} c_{j}}`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1.2401em",
                        "verticalAlign": "-0.4358em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mop"
                      }}><span parentName="span" {...{
                          "className": "mop op-symbol small-op",
                          "style": {
                            "position": "relative",
                            "top": "0em"
                          }
                        }}>{`∑`}</span><span parentName="span" {...{
                          "className": "msupsub"
                        }}><span parentName="span" {...{
                            "className": "vlist-t vlist-t2"
                          }}><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.8043em"
                                }
                              }}><span parentName="span" {...{
                                  "style": {
                                    "top": "-2.4003em",
                                    "marginLeft": "0em",
                                    "marginRight": "0.05em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "pstrut",
                                    "style": {
                                      "height": "2.7em"
                                    }
                                  }}></span><span parentName="span" {...{
                                    "className": "sizing reset-size6 size3 mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}><span parentName="span" {...{
                                        "className": "mord mathnormal mtight",
                                        "style": {
                                          "marginRight": "0.05724em"
                                        }
                                      }}>{`j`}</span><span parentName="span" {...{
                                        "className": "mrel mtight"
                                      }}>{`=`}</span><span parentName="span" {...{
                                        "className": "mord mtight"
                                      }}>{`0`}</span></span></span></span><span parentName="span" {...{
                                  "style": {
                                    "top": "-3.2029em",
                                    "marginRight": "0.05em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "pstrut",
                                    "style": {
                                      "height": "2.7em"
                                    }
                                  }}></span><span parentName="span" {...{
                                    "className": "sizing reset-size6 size3 mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}><span parentName="span" {...{
                                        "className": "mord mathnormal mtight"
                                      }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                                "className": "vlist-s"
                              }}>{`​`}</span></span><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.4358em"
                                }
                              }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "mspace",
                        "style": {
                          "marginRight": "0.1667em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord"
                      }}><span parentName="span" {...{
                          "className": "mord mathnormal"
                        }}>{`c`}</span><span parentName="span" {...{
                          "className": "msupsub"
                        }}><span parentName="span" {...{
                            "className": "vlist-t vlist-t2"
                          }}><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.3117em"
                                }
                              }}><span parentName="span" {...{
                                  "style": {
                                    "top": "-2.55em",
                                    "marginLeft": "0em",
                                    "marginRight": "0.05em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "pstrut",
                                    "style": {
                                      "height": "2.7em"
                                    }
                                  }}></span><span parentName="span" {...{
                                    "className": "sizing reset-size6 size3 mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}><span parentName="span" {...{
                                        "className": "mord mathnormal mtight",
                                        "style": {
                                          "marginRight": "0.05724em"
                                        }
                                      }}>{`j`}</span></span></span></span></span><span parentName="span" {...{
                                "className": "vlist-s"
                              }}>{`​`}</span></span><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.2861em"
                                }
                              }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span>{` where `}<span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`j`}</mi><mo parentName="mrow" {...{
                          "mathvariant": "normal"
                        }}>{`≠`}</mo><mi parentName="mrow">{`i`}</mi></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`j \\neq i`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.8889em",
                        "verticalAlign": "-0.1944em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.05724em"
                      }
                    }}>{`j`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2778em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mrel"
                    }}><span parentName="span" {...{
                        "className": "mrel"
                      }}><span parentName="span" {...{
                          "className": "mord vbox"
                        }}><span parentName="span" {...{
                            "className": "thinbox"
                          }}><span parentName="span" {...{
                              "className": "rlap"
                            }}><span parentName="span" {...{
                                "className": "strut",
                                "style": {
                                  "height": "0.8889em",
                                  "verticalAlign": "-0.1944em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "inner"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mrel"
                                  }}>{``}</span></span></span><span parentName="span" {...{
                                "className": "fix"
                              }}></span></span></span></span></span><span parentName="span" {...{
                        "className": "mrel"
                      }}>{`=`}</span></span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2778em"
                      }
                    }}></span></span><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.6595em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`i`}</span></span></span></span></span></td>
        </tr>
      </tbody>
    </table>
    <p>{`The first logical and simplest step is to try and express our desired
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`c`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`c_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5806em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` in an
algebraic form from the perspective of any one of the friends like so:`}</p>
    <p>{`Here we are expressing `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`c`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`c_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5806em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` as the difference between the sum of total colors
in the group
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{`, and the sum of colors any one friend can see
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`S`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`S_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8333em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.05764em"
                  }
                }}>{`S`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0576em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`.`}</p>
    <p>{`Now the only variable any one of the friends know is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`S`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`S_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8333em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.05764em"
                  }
                }}>{`S`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0576em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` (since
they can each see each others hats), and since our final goal is to solve for
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`c`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`c_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5806em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`, the only
unknown we need to reason about is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{`.`}</p>
    <p>{`Here we need to make a small leap of insight. The game master by choosing
some combination of colored hats, also decides the value `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{` which is bounded by
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "fence": "true"
                  }}>{`[`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`(`}</mo><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`)`}</mo></mrow><mi parentName="mrow">{`n`}</mi><mo parentName="mrow" {...{
                    "fence": "true"
                  }}>{`]`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\left[0, {\\left(n - 1\\right)} n\\right]`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`[`}</span><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.1667em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "minner"
                  }}><span parentName="span" {...{
                      "className": "mopen delimcenter",
                      "style": {
                        "top": "0em"
                      }
                    }}>{`(`}</span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`n`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2222em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mbin"
                    }}>{`−`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2222em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`1`}</span><span parentName="span" {...{
                      "className": "mclose delimcenter",
                      "style": {
                        "top": "0em"
                      }
                    }}>{`)`}</span></span></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`]`}</span></span></span></span></span></span>{`. `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{`
is tricky to understand because the game master effectively is able chooses
any number in this bound. So what do we do?  In situations like this where we
have a complicated variable to reason about, it's often advisable to try
breaking it down or representing it as something more digestible with
different constraints to try and make forward progress.`}</p>
    <p>{`One of the simplest ways to re-represent bounded variables like
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{` is by turning
it into a linear form like so:`}</p>
    <p>{`for some a and b with the following constraints: `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi><mo parentName="mrow">{`≥`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mi parentName="mrow">{`b`}</mi><mo parentName="mrow">{`<`}</mo><mi parentName="mrow">{`n`}</mi><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`≥`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`<`}</mo><mi parentName="mrow">{`n`}</mi><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mi parentName="mrow">{`T`}</mi><mo parentName="mrow">{`≤`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`(`}</mo><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`)`}</mo></mrow><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b \\geq 0 , b < n , a \\geq 0 , a < n , T \\leq {\\left(n - 1\\right)} n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8304em",
                  "verticalAlign": "-0.136em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≥`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8304em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≥`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8389em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8778em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≤`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "minner"
                }}><span parentName="span" {...{
                    "className": "mopen delimcenter",
                    "style": {
                      "top": "0em"
                    }
                  }}>{`(`}</span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`n`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2222em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mbin"
                  }}>{`−`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2222em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`1`}</span><span parentName="span" {...{
                    "className": "mclose delimcenter",
                    "style": {
                      "top": "0em"
                    }
                  }}>{`)`}</span></span></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`. In this form, no matter
what value `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{` is,
there will always exist a unique a and b equal to that total.`}</p>
    <p>{`Substitute everything together, we get:`}</p>
    <p>{`It might seem like we've gone backwards by adding two more "unknowns" to our
equation, but by doing this we've added more ways to tackle our problem and
integrate new information to help us solve it.`}</p>
    <p>{`At this point we need to make `}<strong parentName="p">{`yet another`}</strong>{` small leap of insight. Mentioned
in puzzle definition is the variable `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`i`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`i`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6595em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span></span></span></span></span>{` which tells us that all of the friends have an
order, or an "id". This is information we can use!`}</p>
    <p>{`If we look closely, since every friend has a unique `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`i`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`i`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6595em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span></span></span></span></span>{`, this means that no matter what
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{` the game
master chooses, if we represent `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{` in the linear form, exactly `}<inlineCode parentName="p">{`one`}</inlineCode>{` of the friends
will have an `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`i`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`i`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6595em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span></span></span></span></span>{`
that is equal to `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` (since `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` will be bounded from `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`0`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span></span></span></span></span>{` to `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n - 1`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6667em",
                  "verticalAlign": "-0.0833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span></span></span></span></span>{`). Knowing this we can do the following
cancellation:`}</p>
    <p>{`And almost like magic, we've substituted one of our unknown terms
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` with a term we
do know, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`i`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`i`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6595em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span></span></span></span></span>{`.`}</p>
    <p>{`This leads us to `}<strong parentName="p">{`one last piece`}</strong>{` of insight we need to make to get our final
answer. We know `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`c`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`c_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5806em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` is bounded by `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "fence": "true"
                  }}>{`[`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                    "fence": "true"
                  }}>{`]`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\left[0, n - 1\\right]`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`[`}</span><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.1667em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`−`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`1`}</span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`]`}</span></span></span></span></span></span>{`, which means if we apply `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mspace parentName="mrow"></mspace><mspace parentName="mrow" {...{
                    "width": "0.6667em"
                  }}></mspace><mrow parentName="mrow"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`m`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`o`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`d`}</mi></mrow><mtext parentName="mrow">{` `}</mtext><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mod n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mspace allowbreak"
              }}></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.6667em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathrm"
                  }}>{`mod`}</span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`, it will have no
effect. However if we apply it to both sides, we will actually be able to
eliminate the `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`an`}</span></span></span></span></span>{` term entirely (because any multiple of
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{` is just 0 in
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mspace parentName="mrow"></mspace><mspace parentName="mrow" {...{
                    "width": "0.6667em"
                  }}></mspace><mrow parentName="mrow"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`m`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`o`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`d`}</mi></mrow><mtext parentName="mrow">{` `}</mtext><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mod n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mspace allowbreak"
              }}></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.6667em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathrm"
                  }}>{`mod`}</span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`).`}</p>
    <p>{`By applying this operation we get our final expression:`}</p>
    <p>{`And we've arrived at an interesting conclusion! In order to get a single
friend to guess their own color, all we have to do is have each friend take
their index, subtract the sum of the colors they see, and mod by `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`! In
doing this we guarantee that one of the friends will correctly guess their
own hat!`}</p>
    <p>{`In this final form, all any friend has to do is:`}</p>
    <p>{`And one will guess correctly!`}</p>
    <p>{`Congratulations! The game master, stupefied that one of you was able to
guess correctly, begrudgingly releases you!`}</p>
    <p>{`Thanks for reading! (◍＞◡＜◍)⋈。✧♡`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      